<template>
  <div>
    <div>
        <el-input size="medium" clearable v-model="Text" style="width: 200px; padding: 0px 10px 0px 10px;" @keyup.enter.native="GetAllMaterial()" :placeholder="$t('All.工单') + '/' + $t('All.料号')"></el-input>
        <el-button type="primary" icon="el-icon-search" size="small" @click="GetAllMaterial()">{{$t('All.搜索')}}</el-button>
      </div>
    <div>
      <template>
        <el-table :data="formData" style="width: 100%">
        <el-table-column prop="Date" :label="$t('All.日期')" width="160">
        </el-table-column>
        <el-table-column prop="Order_no" :label="$t('All.工单号')"> </el-table-column>
        <el-table-column prop="Material_name" :label="$t('All.品名')"> </el-table-column>
        <el-table-column prop="Specifications" :label="$t('All.规格')"> </el-table-column>
        <el-table-column prop="Supplier" :label="$t('All.供应商')"> </el-table-column>
        <el-table-column prop="Item_no" :label="$t('All.料号')"></el-table-column>
        <el-table-column prop="Feeding_date" :label="$t('All.进料日期')"> </el-table-column>
        <el-table-column prop="Person" :label="$t('All.负责人')"> </el-table-column>
        <el-table-column prop="Quantity" :label="$t('All.数量')"> </el-table-column>
        <el-table-column prop="Username" :label="$t('All.扫描人')"> </el-table-column>
        <el-table-column prop="Type" :label="$t('All.类型')" >
          <template slot-scope="scope">
            {{$PublicJs.WUliaotype(scope.row.Type)}}
            </template>
        </el-table-column>
      </el-table>
      </template>
    </div>
    <el-pagination
    style="text-align: center; padding: 20px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 100, 200, 300]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      formData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: ''
    }
  },
  filters: {
    formatDate: function (value, args) {
      var dt = new Date(value)
      if (args === 'yyyy-M-d') { // yyyy-M-d
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        return `${year}-${month}-${date}`
      } else if (args === 'yyyy-M-d H:m:s') { // yyyy-M-d H:m:s
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        const hour = dt.getHours()
        const minute = dt.getMinutes()
        const second = dt.getSeconds()
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      } else if (args === 'yyyy-MM-dd') { // yyyy-MM-dd
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${date}`
      } else { // yyyy-MM-dd HH:mm:ss
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        const hour = dt.getHours().toString().padStart(2, '0')
        const minute = dt.getMinutes().toString().padStart(2, '0')
        const second = dt.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.GetAllMaterial()
  },
  mounted () {},
  methods: {
    async GetAllMaterial () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text
      }
      const { data: res } = await this.$http.post(
        '/api/Semifinished/GetAllMaterial',
        intfs
      )
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.formData = res.response.Material
    },
    handleSizeChange (val) {
      this.page_size = val
      this.GetAllMaterial()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.GetAllMaterial()
    }
  }
}

</script>
<style lang="less" scoped>
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
